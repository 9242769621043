<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="loginUser">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Email"
                    autocomplete="username email"
                    v-model="username"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        type="submit"
                        :disabled="loading || !formIsValid"
                      >
                        <span v-if="loading"
                          ><loader-icon
                            size="1.5x"
                            class="custom-class"
                          ></loader-icon
                          >Please Wait</span
                        >
                        <span v-else>Login</span>
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton
                        color="link"
                        class="px-0"
                        :to="{ name: 'ResetPassword' }"
                        >Forgot password?</CButton
                      >
                      <CButton color="link" class="d-lg-none"
                        >Register now!</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h1>Sign up</h1>
                <!-- <p>
                  Hello, You can sign up to be a OneCentral Agent by pressing
                  the button below.
                </p> -->
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                  href="https://my.onecentral.ng/agent/register"
                  target="_blank"
                  class="mt-2"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    loading() {
      return this.$store.getters.loading;
    },
    formIsValid() {
      return this.username !== "" && this.password !== "";
    },
  },
  methods: {
    loginUser() {
      let data = {
        username: this.username,
        password: this.password,
      };
      //console.log(data)
      this.$store.dispatch("loginUser", data);
    },
  },
};
</script>
